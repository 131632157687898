import { Component, HostListener, TemplateRef } from '@angular/core';
import { Location } from '@angular/common';
import { AsideExtenderService } from 'src/app/data/service/aside-extender.service';
import { Position, Type } from 'src/app/utils/const';
import * as moment from 'moment';
import { BsModalRef, BsModalService, ModalOptions } from 'ngx-bootstrap/modal';
import { AppAuthGuardService } from 'src/app/data/service/app-auth-guard.service';
import { ClusterService } from '../../../../clusters/services/cluster.service';
import { Cluster } from 'src/app/data/model/cluster';
import { GetRecordsModel } from 'src/app/data/model/GetRecordsModel';
import { Router } from '@angular/router';
import { DeploymentService } from '../../../services/deployment.service';
import { Deployment } from 'src/app/data/model/deployment';
import {
  UntypedFormControl,
  UntypedFormGroup,
  Validators,
} from '@angular/forms';
import { equalsTo } from 'src/app/modules/app/registry/registry.component';

@Component({
  selector: 'app-deployment',
  templateUrl: './deployment.component.html',
  styleUrls: ['./deployment.component.scss'],
})
export class DeploymentComponent {
  formSubmited = false;
  deployments: Deployment[] = [];
  currentModal: string = '';
  deploymentLoaded: boolean = false;
  alerting: boolean = false;
  disabledBtn = false;
  modalRef: BsModalRef;
  currentDeployment: Deployment;
  clusters: Cluster[] = [];
  currentCluster: Cluster;
  total = 0;
  page = 1;
  limit = 7;
  search = '';

  deleteDeployForm: UntypedFormGroup;
  dropdownStates: boolean[] = [];
  ignoreClick = false;

  deploymentTypeFailed = ['ReleaseFailed'];

  constructor(
    private _location: Location,
    private modalService: BsModalService,
    private service: AsideExtenderService,
    private deploymentService: DeploymentService,
    private clusterService: ClusterService,
    private router: Router,
    public auth: AppAuthGuardService
  ) {}

  ngOnInit(): void {
    this.getAllDeployments(this.page, this.limit, this.search);
    this.dropdownStates = [];
  }

  onCloseBanner(elId) {
    setTimeout(() => {
      document.getElementById(elId).innerHTML = '';
      document.getElementById(elId).style.width = '0';
      document.getElementById(elId).style.padding = '0';
      document.getElementById(elId).style.background = 'transparent';
    }, 100);
  }

  toggleDropdown(index: number) {
    this.dropdownStates = this.deployments.map((_, i) =>
      i === index ? !this.dropdownStates[i] : false
    );
    this.ignoreClick = true;
    setTimeout(() => {
      this.ignoreClick = false;
    }, 0);
  }
  @HostListener('document:click', ['$event'])
  onClickOutside(event: MouseEvent) {
    const containsTrue = this.dropdownStates.some((value) => value === true);
    if (containsTrue && !this.ignoreClick) {
      this.dropdownStates = [];
    }
  }
  onCreate() {
    this.router.navigate(['/deploy/create']);
  }

  onEdit(deployment: Deployment) {
    this.router.navigate(['/deploy/edit/' + deployment.id], {
      queryParams: {
        projectId: deployment.projectId,
        environmentId: deployment.environmentId,
      },
    });
  }

  onGotoDeliver(deployment: Deployment) {
    if (deployment.workloadId) {
      this.router.navigate(['/deliver/' + deployment.workloadId], {
        queryParams: {
          proj: deployment.projectId,
          env: deployment.environmentId,
        },
      });
    }
  }

  onDuplicate(deployment: Deployment) {
    this.router.navigate(['/deploy/create'], {
      queryParams: {
        id: deployment.id,
        projectId: deployment.projectId,
        environmentId: deployment.environmentId,
        duplicate: true,
      },
    });
  }

  getClusters(): void {
    this.clusterService.getAll().subscribe((data) => {
      this.deploymentLoaded = true;
      this.clusters = data.records;
    });
  }

  deploymentIsFailed(deployment: Deployment, checkReplicas=true) {
    const conditions = deployment?.status?.conditions;

    let failed = false;

    if (conditions) {
      const conditionFailed = conditions.find((cond: any) => this.deploymentTypeFailed.includes(cond.type));

      if (conditionFailed) {
        failed = true;
      }

      if ((deployment?.liveReadyReplicas != deployment?.desiredReplicas)) {
        failed = true;
      }

      if (!checkReplicas && conditionFailed) {
        failed = false;
      }
    }

    return failed;
  }


  elipsize(str: string) {
    if (str) {
      if (str.length > 30) {
        return str.substring(0, 30) + '...';
      }
      return str;
    }
    return '';
  }
  getClusterName(clusterId) {
    const cluster = this.clusters.find((c) => c.id == clusterId);
    if (cluster) {
      return cluster.name;
    }
    return 'Not found';
  }

  async onCopyApiGatewayUrl(clusterId: any) {
    if (this.clusters.length > 0) {
      const findCluster = this.clusters.find((c) => c?.id == clusterId);
      if (findCluster) {
        try {
          await navigator.clipboard.writeText(findCluster?.apiGatewayAddresses);
          this.service.show({
            title: 'Copy success',
            message: 'Link has been copied',
            type: Type.SUCCESS,
            position: Position.TOP,
          });
        } catch (err) {
          this.service.show({
            title: 'Copy error',
            message: err,
            type: Type.ERROR,
            position: Position.TOP,
          });
        }
      }
    }
  }

  getAllDeployments(page: any, limit: any, search: any): void {
    this.deploymentLoaded = false;
    this.deploymentService.getAll(page, limit, search).subscribe(
      (response: GetRecordsModel<Deployment>) => {
        this.deployments = response.records;
        this.page = response.page;
        this.limit = response.limit;
        this.total = response.totalRecord;

        this.getClusters();
      },
      (err) => {
        this.service.show({
          type: Type.ERROR,
          message: err.error,
          title: 'Deployment',
          position: Position.TOP,
        });
        this.deploymentLoaded = true;
      }
    );
  }

  getPage(page: number) {
    this.getAllDeployments(page, this.limit, this.search);
    this.dropdownStates = [];
  }

  onUpSearch(search: any): void {
    if (search.length > 0) {
      this.search = search;
      this.getAllDeployments(1, this.limit, this.search);
    } else {
      this.getAllDeployments(1, this.limit, '');
    }
  }

  onOpenAction(id, state) {
    // state = state == 'show' ? 'show' : 'hide';
    // if (state=='show') {
    //   $(`.${id}`).addClass(state);
    // }else{
    //   $(`.${id}`).removeClass(state);
    // }
  }

  formatDateToHuman(dateStr) {
    const date = moment(dateStr);
    return date.fromNow();
  }

  goBack(): void {
    this._location.back();
  }

  onUpDeploymentName(name: any): void {
    if (this.currentDeployment?.metadata?.name === name) {
      this.disabledBtn = false;
    } else {
      this.disabledBtn = true;
    }
  }

  onOpenDeleteModal(template: TemplateRef<any>, deployment: Deployment) {
    this.currentDeployment = deployment;
    this.disabledBtn = true;
    this.deleteDeployForm = new UntypedFormGroup({
      name: new UntypedFormControl('', [
        Validators.required,
        equalsTo(this.currentDeployment.metadata?.name),
      ]),
      forced: new UntypedFormControl(false),
    });

    const config: ModalOptions = {
      class: 'modal-md modal-dialog-centered',
      backdrop: 'static',
    };
    this.modalRef = this.modalService.show(template, config);
  }

  onOpenInfoStatusModal(template: TemplateRef<any>, deployment: Deployment) {
    this.currentDeployment = deployment;

    const config: ModalOptions = {
      class: 'modal-md modal-dialog-centered',
    };

    this.modalRef = this.modalService.show(template, config);
  }

  onCloseDialog(): void {
    this.modalRef.hide();
  }

  onSubmitDeleteDeployment(): void {
    if (this.deleteDeployForm.valid) {
      this.deploymentService
        .deleteDeployment(
          this.currentDeployment?.projectId,
          this.currentDeployment?.environmentId,
          this.currentDeployment?.id,
          this.deleteDeployForm.get('forced').value
        )
        .subscribe(
          () => {
            this.getAllDeployments(this.page, this.limit, this.search);
            this.service.show({
              title: 'Delete Deployment',
              message: 'Deployment deleted successfully',
              type: Type.SUCCESS,
              position: Position.TOP,
            });
            this.onCloseDialog();
          },
          (error) => {
            this.service.show({
              title: 'Delete Deployment',
              message: error?.error,
              type: Type.ERROR,
              position: Position.TOP,
            });
            this.onCloseDialog();
          }
        );
    }
  }
}
